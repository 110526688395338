import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from "react-oidc-context";
import reportWebVitals from './reportWebVitals';

const oidcConfig = {
  authority: "https://login.microsoftonline.com/ad19d7e1-608e-441e-b9ea-cc3f9ead3f86/v2.0",
  client_id: "d5668879-9c13-4628-9697-bec525928539",
  redirect_uri: "https://lpazuredev.j6dev.net",
  scope: "email openid profile https://msazure.j6dev.net/launchpadapi/Employees.ReadWrite"
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
