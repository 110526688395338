// src/App.jsx
import React from "react";
import { useAuth } from "react-oidc-context";

function App() {
    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
        <div style={{ padding: "35px" }}>
            <p>Hello {auth.user?.profile.sub}{" "}</p>
            <br />
            <p>Here is your access_token:</p>
            <pre style={{ backgroundColor: "#efefef",padding: "15px", whiteSpace: "pre-wrap", wordBreak: "break-all" }}>{auth.user?.access_token}</pre>
            <br />
            <p>Here is your id_token:</p>
            <pre style={{ backgroundColor: "#efefef",padding: "15px", whiteSpace: "pre-wrap", wordBreak: "break-all" }}>{auth.user?.id_token}</pre>
            <br />
            <br />
            <button onClick={() => void auth.removeUser()}>Log out</button>
        </div>
        );
    }

    return <>

        <div style={{padding: '15px'}}>
          <button onClick={() => void auth.signinRedirect()}>Log in</button>
        </div>

      </>;
}

export default App;
